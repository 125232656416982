import { AgreementID } from "../../enums/agreementID.enum"

import { NSDQOTUTPAgreementFormData, GeneralAgreementFormData, CBOEProfessionalFormData, NyseAgreementFormData,
    BlueOceanAgreementFormData, NASDAQTotalViewAgreementFormData, OpraAgreementFormData
 } from "./AgreementFormDataMapping"

export const AgreementFormDataResponse = (formData: any, currentAgreement: any) => {
    switch (currentAgreement.agreementID) {
        case AgreementID.GENERAL:
            return GeneralAgreementFormData(formData);
        case AgreementID.NASDAQ_NP:
            return NSDQOTUTPAgreementFormData(formData);
        case AgreementID.NYSE_AMEX_NP:
            return NyseAgreementFormData(formData);
        case AgreementID.NASDAQ_TV_NP:
            return NASDAQTotalViewAgreementFormData(formData);
        case AgreementID.OPRA_NP:
            return OpraAgreementFormData(formData);
        case AgreementID.CBOE_NP:
            return CBOEProfessionalFormData(formData);
        case AgreementID.BLUE_OCEAN:
            return BlueOceanAgreementFormData(formData);
    }
}