/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/

const LoadingSpinner = () => (
    <div className="width-100-vw height-100-vh bg-color-paper-1">
    <div className="text-center position-absolute top-50 start-50 translate-middle display-flex jc-center">
        <div className="spinner-border " role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        <div className="spinner-text ml-md txt-color-body-shaded-2">Submitting Agreement...</div>
    </div>
</div>
);

export default LoadingSpinner;