/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/

import { Message } from "../../enums/message.enum";
import { ResponseState } from "../../enums/responseState.enum";
import { feedLevelMap, regionMap, userTypeMap } from "../../mappings/upgradeDataMapping";

export const createSubscriptionDataObject = (subscriptionData: any) => {

    const region = subscriptionData?.region !== undefined ? regionMap.get(subscriptionData.region) : undefined;
    const feedLevel = subscriptionData?.feedLevel !== undefined ? feedLevelMap.get(subscriptionData.feedLevel) : undefined;
    const userType = subscriptionData?.userType !== undefined ? userTypeMap.get(subscriptionData.userType) : undefined;
    
    const userSubUpgradeDataOBJ = {
        userId: subscriptionData?.customerNumber,
        exchange: subscriptionData?.exchange,
        userIP : subscriptionData?.userIP,
        channel:subscriptionData?.channel,
        region : region,
        feedLevel: feedLevel,
        userType: userType        
    };
    return userSubUpgradeDataOBJ;
}

export const createAllAgreedMessageObject = (data: any) => {
    const allAgreedMessage = {
        messageType: Message.allAgreed,
        status: ResponseState.ok,
        data:{
            exchange:data?.exchange || null,
            feedLevel:data?.feedLevel || null,
            userId:data?.userId || null,
            userType:data?.userType || null,
            agreed:true
        },

    };

    return allAgreedMessage;
}

export const createUserSignedDataObject = (subscribeUserData: any, generatedUserID: any,subscriptionRequestID: any, agreementID: any, encryptedData: any) => {
    const userSignedInfo = {
        isAgreement: ResponseState.true,
        isLegacyAgreement: false,        
        agreementID : agreementID,
        generatedUserID : generatedUserID,
        subscriptionID: subscriptionRequestID,
        userId : subscribeUserData?.userId,
        exchange : subscribeUserData?.exchange,
        feedLevel: subscribeUserData?.feedLevel,
        userType: subscribeUserData?.userType,
        region: subscribeUserData?.region,
        userIP : subscribeUserData?.userIP,
        channel: subscribeUserData?.channel,
        formData: encryptedData || [],
    };

    return userSignedInfo;
}