/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/
 
export const NSDQOTUTPAgreementFormData = (formData: any) => {
    const FullName = formData.FirstName + " " + formData.LastName;
    const FormDataFields = {
        subscriberName: FullName,
        subscriberFirstName: formData.FirstName,
        subscriberLastName : formData.LastName,
        subscriberTitle: formData.JobTitle,
        subscriberTelephone: formData.Mobile,
        subscriberAddressOne: formData.AddressOne,
        subscriberAddressTwo: formData.AddressTwo,
        subscriberEmail: formData.Email
    }
    return FormDataFields;
}

export const GeneralAgreementFormData = (formData: any) => {
    const FullName = formData.FirstName + " " + formData.LastName;
    const FormDataFields = {
        subscriber_userID: formData.OMS_UserID,
        subscriberName: FullName,
        subscriberAddress: formData.AddressOne,
        subscriberCity : formData.City,
        subscriberCountry: formData.Country,
        subscriberEmail: formData.Email,
    }
    return FormDataFields;
}

export const CBOEProfessionalFormData = (formData: any) => {
    const FullName = formData.FirstName + " " + formData.LastName;
    const FormDataFields = {
        subscriberName: FullName,
        subscriberEmail: formData.Email,
    }
    return FormDataFields;
}

export const NyseAgreementFormData = (formData: any) => {
    const FormDataFields = {
        subscriberFirstName: formData.FirstName,
        subscriberLastName:formData.LastName,
        subscriberAddressOne: formData.AddressOne,
        subscriberAddressTwo: formData.AddressTwo,
        subscriberTitle: formData.JobTitle
    }
    return FormDataFields;
}

export const OpraAgreementFormData = (formData: any) => {
    const FullName = formData.FirstName + " " + formData.LastName;
    const FormDataFields = {
        subscriberName: FullName,
        subscriberAddress: formData.AddressOne,
    }
    return FormDataFields;
}

export const BlueOceanAgreementFormData = (formData: any) => {
    const FullName = formData.FirstName + " " + formData.LastName;
    const FormDataFields = {
        subscriberName: FullName,
    }
    return FormDataFields;
}

export const NASDAQTotalViewAgreementFormData = (formData: any) => {
    const FormDataFields = {
        subscriberFirstName:formData.FirstName,
        subscriberTitle: formData.JobTitle,
        subscriberAddress:formData.AddressOne,
        subscriberTelephone: formData.Mobile,
        subscriberEmail: formData.Email
    }
    return FormDataFields;
}
