import { useState } from "react";

export const useInputHandlers = (initialValues: any) => {
  const [inputValues, setInputValues] = useState(initialValues);

  const [errors, setErrors] = useState({
    subscriberName: '',
    subscriberAddress: '',
  });

  // Handle input change for all fields
  const handleInputChange = (name: string, value: string) => {
    setInputValues({ ...inputValues, [name]: value });

    // Clear error if the user starts typing
    if (value.trim()) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  // Handle error event for validation
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // Check if field is empty and set appropriate error message
    if (!value.trim()) {
      setErrors({ ...errors, [name]: 'Please enter valid data' });
    }
  };

  return { inputValues, errors, handleInputChange, handleBlur };
};
