/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/

const searchEmptyFields = (formData: any, requiredFields: string[]) => {
    const emptyFields = [];

    for (const field of requiredFields) {
        if (!(field in formData) || formData[field] === "") {
            emptyFields.push(field);
        }
    }
    return emptyFields;
};

export const validateFormDataGeneralAgreementResponse = (formData: any) => {
    const requiredFields = [
        "subscriber_userID",
        "subscriberSignature",
        "subscriberName",
        "subscriberAddress",
        "subscriberCity",
        "subscriberCountry",
        "subscriberEmail",
        "subscriberInternalReference",
    ];

    const emptyFields = searchEmptyFields(formData, requiredFields);

    if (emptyFields.length > 0) {
        return { success: false, invalidFields: emptyFields };
    }

    return { success: true, data: formData };
}

export const validateFormDataNASDAQUtpNonProResponse = (formData: any) => {
    const requiredFields = [       
        "professional",
        "subscriberSignature",
        "subscriberName",
        "subscriberFirstName",
        "subscriberLastName",
        "subscriberTitle",
        "subscriberTelephone",
        "subscriberEmail",
        "subscriberCopyTo",
        "subscriberAddressOne",
        "subscriberAddressTwo",
        "checkbox"
    ];

    const emptyFields = searchEmptyFields(formData, requiredFields);
    if (emptyFields.length > 0) {
        return { success: false, invalidFields: emptyFields };
    }
    return { success: true, data: formData };
};

export const validateFormDataBlueOceanResponse = (formData: any) => {
    const requiredFields = [
        "subscriberName",
        "subscriberSignature",
        "subscriber_type"
    ];

    const emptyFields = searchEmptyFields(formData, requiredFields);

    if (emptyFields.length > 0) {
        return { success: false, invalidFields: emptyFields };
    }
    return { success: true, data: formData };
};

export const validateFormDataNASDAQTotalViewResponse = (formData: any) => {
    const requiredFields = [
        "subscriberFirstName",
        "subscriberTitle",
        "subscriberAddress",
        "subscriberTelephone",
        "subscriberEmail",
        "subscriberCopyTo",
        "subscriberSignature"
    ];

    const emptyFields = searchEmptyFields(formData, requiredFields);

    if (emptyFields.length > 0) {
        return { success: false, invalidFields: emptyFields };
    }
    return { success: true, data: formData };
};

export const validateFormDataOpraSubscriberResponse = (formData: any) => {
    const requiredFields = [
        "subscriberName",
        "subscriberAddress",
        "section1_0_agree",
        "section2_0_agree",
        "section2_1_agree",
        "section2_2_agree",
        "section2_3_agree"
    ];

    const emptyFields = searchEmptyFields(formData, requiredFields);
    if (emptyFields.length > 0) {
        return { success: false, invalidFields: emptyFields };
    }
    return { success: true, data: formData };
};

export const validateFormDataNyseSubscriberResponse = (formData: any) => {
    const requiredFields = [
        "subscriberFirstName",
        "subscriberLastName",
        "subscriberAddressOne",
        "subscriberAddressTwo",
        "subscriberTitle",
        "employerOccupation",
        "employerName",
        "employerStreet",
        "employerCity",
        "subscriberEmploymentFunctions",
        "certificationOk",
        "acceptedAndAgreedSec2",
        "acceptedAndAgreedSec1"
    ];

    const emptyFields = searchEmptyFields(formData, requiredFields);

    if (emptyFields.length > 0) {
        return { success: false, invalidFields: emptyFields };
    }
    return { success: true, data: formData };
};

export const validateFormDataCBOEProfessionalResponse = (formData: any) => {
    const requiredFields = [
        "subscriberName",
        "subscriberEmail",
        "subscriberSignature",
        "subscriber_type"
    ];

    const emptyFields = searchEmptyFields(formData, requiredFields);

    if (emptyFields.length > 0) {
        return { success: false, invalidFields: emptyFields };
    }
    return { success: true, data: formData };
};