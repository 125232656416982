/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
 */
import { Message } from "../../enums/message.enum";
import { sendMessageToParentApp } from "../../util/UtilService";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


const InitialPage = () => {
  const handleCancel = async () => {
    // Message sender to the parent application
    sendMessageToParentApp({ messageType: Message.cancelSubmission });
  };

  return (
    <div className="width-100-vw height-100-vh bg-color-paper-1">
      <div className="position-absolute top-50 start-50 translate-middle b-sm-shaded-5">
        <div className="b-b-sm-shaded-5 height-250-px ">
          <div className="txt-size-md text-white text-center bg-color-paper-5 ptb-md">
            No agreements to show
          </div>
          <div className="txt-size-sm text-center txt-align-justify  p-xl txt-color-warning d-flex flex-column align-items-center"> 
            <ErrorOutlineIcon color="error" style={{ fontSize: 80 }} />          
            It looks like something went wrong while processing your agreements.
            Please be patient and try again shortly. If the issue persists,
            please contact our support team for help.
          </div>
        </div>
        <div className="display-flex jc-center ptb-md">
          <button
            className="txt-color-body-shaded-4 b-sm-shaded-5 bg-color-paper-1 txt-size-xs"
            onClick={handleCancel}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default InitialPage;
