/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/

import { AgreementID } from '../../enums/agreementID.enum';
import {
  validateFormDataGeneralAgreementResponse,
  validateFormDataCBOEProfessionalResponse,
  validateFormDataBlueOceanResponse,
  validateFormDataNASDAQTotalViewResponse,
  validateFormDataNASDAQUtpNonProResponse,
  validateFormDataOpraSubscriberResponse,
  validateFormDataNyseSubscriberResponse
} from '../validation/AgreementDataValidations';


export const validateFormDataFromAgreementDataResponse = (formData: any, currentAgreement: any) => {

  switch (currentAgreement.agreementID) {
    case AgreementID.GENERAL:
      return validateFormDataGeneralAgreementResponse(formData);
    case AgreementID.NASDAQ_NP:
      return validateFormDataNASDAQUtpNonProResponse(formData);
    case AgreementID.NYSE_AMEX_NP:
      return validateFormDataNyseSubscriberResponse(formData);
    case AgreementID.NASDAQ_TV_NP:
      return validateFormDataNASDAQTotalViewResponse(formData);
    case AgreementID.OPRA_NP:
      return validateFormDataOpraSubscriberResponse(formData);
    case AgreementID.CBOE_NP:
      return validateFormDataCBOEProfessionalResponse(formData);
    case AgreementID.BLUE_OCEAN:
      return validateFormDataBlueOceanResponse(formData);
  }

};