/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/

import { useEffect, useState } from 'react';
import '../../../assets/scss/index.scss';
import '../../../assets/themes/theme1-color-variables.css';
import '../../../assets/themes/theme2-color-variables.css';
import { useInputHandlers } from './NYSEAgreementValidations';

const NYSEAgreementNonPro = ({ onChange, invalidFields, resData,setIsValidated, subsData }: any) => {
    console.log("Web Tech Data", subsData?.customerNumber);
    
    const [answers, setAnswers] = useState({
        set1: null, set2: null, set3: null, set4: null,
        set5: null, set6: null, set7: null, set8: null,
        set9: null, set10: null, set11: null
    });

    const isValid = () => {
        return answers.set1 === 'yes' && answers.set2 === 'no' && answers.set3 === 'no' && answers.set4 === 'no' &&
            answers.set5 === 'no' && answers.set6 === 'no' && answers.set7 === 'no' && answers.set8 === 'no' &&
            answers.set9 === 'no' && answers.set10 === 'no' && answers.set11 === 'no';
    };

    const initialValues = {
      subscriberFirstName: subsData?.userInfo?.firstName
        ? subsData.userInfo.firstName
        : resData?.FirstName
        ? resData.FirstName
        : "",
      subscriberLastName: subsData?.userInfo?.lastName
        ? subsData.userInfo.lastName
        : resData?.LastName
        ? resData.LastName
        : "",
      subscriberTitle: subsData?.userInfo?.jobTitle
        ? subsData.userInfo.jobTitle
        : resData?.JobTitle
        ? resData.JobTitle
        : "",
      subscriberAddressOne: subsData?.userInfo?.addressLine1
        ? subsData.userInfo.addressLine1
        : resData?.AddressOne
        ? resData.AddressOne
        : "",
      subscriberAddressTwo: subsData?.userInfo?.addressLine2
        ? subsData.userInfo.addressLine2
        : resData?.AddressTwo
        ? resData.AddressTwo
        : "",
      subscriberEmploymentFunctions: "",
      employerOccupation: "",
      employerName: "",
      employerStreet: "",
      employerCity: "",
    };

    const { inputValues,errors, handleInputChange, handleBlur } = useInputHandlers(initialValues);
    const [allChecked, setAllChecked] = useState(false);
    const [checkedValues, setCheckedValues] = useState<boolean[]>(Array(3).fill(false));

    const handleChange = (setName: any, value: any) => {
        setAnswers((prev) => ({ ...prev, [setName]: value }));
    };

    useEffect(() => {
        const allFilled = Object.values(answers).every((value) => value !== null);
        setAllChecked(allFilled);
    }, [answers]);

    const handleCheckboxChange = (index: number) => {
        const updatedCheckedValues = [...checkedValues];
        updatedCheckedValues[index] = !updatedCheckedValues[index]; 
        setCheckedValues(updatedCheckedValues);
    };

    useEffect(() => {
        const allFieldsFilled = Object.values(inputValues).every((value: any) => value.trim() !== '');
        const noErrors = Object.values(errors).every((error) => error === '');
        const allCheckboxesChecked = checkedValues.every((checked) => checked === true);

        setIsValidated(allFieldsFilled && noErrors && allCheckboxesChecked && isValid());
    }, [inputValues, errors, checkedValues, isValid()]);

    return (
        <div className='txt-size-xs txt-color-body-shaded-2 p-lg'>
            <div>
                <div className='display-flex jc-center txt-size-sm txt-weight-bold'>AGREEMENT FOR</div>
                <div className='display-flex jc-center txt-size-md txt-weight-bold mt-md'>MARKET DATA DISPLAY SERVICES</div>
                <div className='display-flex jc-center txt-size-sm txt-weight-bold txt-align-center mt-md'>(Usage-Based Services/Nonprofessional Subscriber Status)</div>
                <div className='display-flex jc-center txt-size-sm txt-weight-bold mt-md'>(Electronic Version)</div>
            </div>
            <div className='mt-md'>
                <b className='txt-color-heading-2 txt-align-justify'>GTN Group Holding Ltd </b>("Vendor")
                agrees to make "Market Data" available to you pursuant to the terms and conditions set forth in
                this agreement. By executing this Agreement in the space indicated below, you ("Subscriber")
                agree to comply with those terms and conditions. Section 1 sets forth terms and conditions of
                general applicability. Section 2 applies insofar as Subscriber receives and uses Market Data made
                available pursuant to this Agreement as a Nonprofessional Subscriber.
            </div>
            <div className='ml-md'>
            <div className='mt-sm txt-size-sm txt-weight-bold'>
                <u>SECTION 1: TERMS AND CONDITIONS OF GENERAL APPLICABILITY</u>
            </div>
            <div className='mt-sm'>
                <div className="pl-sm txt-align-justify">
                    <b>1. MARKET DATA DEFINITION – </b>For all purposes of this Agreement, "Market
                    Data" means (a) last sale information and quotation information relating to securities that are
                    admitted to dealings on the New York Stock Exchange ("NYSE"), (b) such bond and other equity
                    last sale and quotation information, and such index and other market information, as United
                    States-registered national securities exchanges and national securities associations (each, an
                    "Authorizing SRO") may make available and as the NYSE may from time to time designate as
                    "Market Data"; and (c) all information that derives from any such information.
                </div>
                <div className="pl-sm mt-sm txt-align-justify">
                    <b>2. PROPRIETARY NATURE OF DATA – </b> Subscriber understands and
                    acknowledges that each Authorizing SRO and Other Data Disseminator has a proprietary interest
                    in the Market Data that originates on or derives from it or its market(s).
                </div>
                <div className="pl-sm mt-sm txt-align-justify">
                    <b>3. ENFORCEMENT – </b> Subscriber understands and acknowledges that (a) the
                    Authorizing SROs are third-party beneficiaries under this Agreement and (b) the Authorizing
                    SROs or their authorized representative(s) may enforce this Agreement, by legal proceedings or
                    otherwise, against Subscriber or any person that obtains Market Data that is made available
                    pursuant to this Agreement other than as this Agreement contemplates. Subscriber shall pay the
                    reasonable attorney's fees that any Authorizing SRO incurs in enforcing this Agreement against
                    Subscriber.
                </div>
                <div className="pl-sm mt-sm txt-align-justify">
                    <b>4. DATA NOT GUARANTEED – </b> Subscriber understands that no Authorizing SRO, no other entity
                    whose information is made available over the Authorizing SROs' facilities (an "Other Data Disseminator")
                    and no information processor that assists any Authorizing SRO or Other Data Disseminator in making Market
                    Data available (collectively, the "Disseminating Parties") guarantees the timeliness, sequence, accuracy
                    or completeness of Market Data or of other market information or messages disseminated by any Disseminating
                    Party. Neither Subscriber nor any other person shall hold any Disseminating Party liable in any way for
                    (a) any inaccuracy, error or delay in, or omission of, (i) any such data, information or message or (ii) the
                    transmission or delivery of any such data, information or message, or (b) any loss or damage arising from or
                    occasioned by (i) any such inaccuracy, error, delay or omission, (ii) nonperformance or (iii) interruption in
                    any such data, information or message, due either to any negligent act or omission by any Disseminating Party,
                    to any "force majeure" (e.g., flood, extraordinary weather conditions, earthquake or other act of God, fire, war,
                    insurrection, riot, labor dispute, accident, action of government, communications or power failure, equipment
                    or software malfunction) or to any other cause beyond the reasonable control of any Disseminating Party
                </div>
                <div className="pl-sm mt-sm txt-align-justify">
                    <b>5. PERMITTED USE – </b> Subscriber shall not furnish Market Data to any other
                    person or entity. If Subscriber receives Market Data other than as a Nonprofessional Subscriber,
                    it shall use Market Data only for its individual use in its business.
                </div>
                <div className="pl-sm mt-sm txt-align-justify">
                    <b>6. DISSEMINATION DISCONTINUANCE OR MODIFICATION – </b>
                    Subscriber understands and acknowledges that, at any time, the Authorizing SROs may
                    discontinue disseminating any category of Market Data, may change or eliminate any
                    transmission method and may change transmission speeds or other signal characteristics. The
                    Authorizing SROs shall not be liable for any resulting liability, loss or damages that may arise
                    therefrom.
                </div>
                <div className="pl-sm mt-sm txt-align-justify">
                    <b>7. DURATION; SURVIVAL – </b> This Agreement remains in effect for so long as
                    Subscriber has the ability to receive Market Data as contemplated by this Agreement. In addition,
                    Vendor may terminate this Agreement at any time, whether at the direction of the Authorizing
                    SROs or otherwise. Paragraphs 2, 3 and 4, and the first two sentences of Paragraph 8, survive any
                    termination of this Agreement.
                </div>
                <div className="pl-sm mt-sm txt-align-justify">
                    <b>8. MISCELLANEOUS –</b> The laws of the State of New York shall govern this
                    Agreement and it shall be interpreted in accordance with those laws. This Agreement is subject to
                    the Securities Exchange Act of 1934, the rules promulgated under that act, and the joint-industry
                    plans entered into pursuant to that act. This writing contains the entire agreement between the
                    parties in respect of its subject matter. Subscriber may not assign all or any part of this
                    Agreement
                    to any other person. The person executing this Agreement below represents and warrants that he
                    or she has legal capacity to contract and, if that person is executing this Agreement on behalf of a
                    proprietorship or a business, partnership or other organization, represents and warrants that he or
                    she has actual authority to bind the organization.
                </div>
                </div>
                <div className="bg-color-paper-3 p-sm mt-md" >
                    <div className='txt-align-justify'>
                        <b>ACCEPTED AND AGREED:</b> I, the "Subscriber" to which the preceding terms
                        and conditions refer, acknowledge that I have read the preceding terms and conditions of
                        this Section 1, that I understand them and that I hereby manifest my assent to, and my agreement
                        to comply with, those terms and conditions by "clicking" on the following box:
                    </div>
                    <div className='display-flex justify-content-end'>
                        <input type="checkbox" id="agreementCheckbox" name="acceptedAndAgreedSec1" value="iAgree" 
                        checked={checkedValues[0]}
                        onChange={event => {
                          onChange(event.target.name, event.target.value);
                          (handleCheckboxChange(0));
                      }}/>  
                    </div>
                </div>
<div className='ml-md'>
                <div className='txt-size-sm txt-weight-bold mt-sm'>
                    <u>SECTION 2: NONPROFESSIONAL SUBSCRIBER</u>
                </div>
                <div className='mt-sm txt-align-justify'>
                    <b>9. NONPROFESSIONAL SUBSCRIBER DEFINITION -</b> "Nonprofessional Subscriber" means
                    any natural person who receives market data solely for his/her personal, nonbusiness
                    use and who is not a “Securities Professional.” A “Securities Professional” includes
                    an individual who, if working in the United States, is:
                    <div className='mt-sm pl-sm'>
                        <div className='mt-sm txt-align-justify'>
                            a. registered or qualified with the Securities and Exchange Commission (the "SEC"),
                            the Commodities Futures Trading Commission, any state securities agency, any securities
                            exchange or association, or any commodities or futures contract market or association
                        </div>
                        <div className='mt-sm txt-align-justify'>
                            b. engaged as an "investment advisor" as that term is defined in Section 202 (a) (11)
                            of the Investment Advisor's Act of 1940 (whether or not registered or qualified under that Act), or
                        </div>
                        <div className='mt-sm txt-align-justify'>
                            c. employed by a bank or other organization exempt from registration under Federal and/or state
                            securities laws to perform functions that would require him or her to be so registered or qualified
                            if he or she were to perform such functions for an organization not so exempt
                        </div>
                    </div>
                    <div className='mt-sm txt-align-justify'>
                        A person who works outside of the United States will be considered a “Securities Professional” if he or she performs
                        the same functions as someone who would be considered a “Securities Professional” in the United States.
                    </div>
                    <div className='mt-sm txt-align-justify'>
                        Subscriber may not receive Market Data as a “Nonprofessional Subscriber” unless the vendor providing that data to Subscriber
                        first determines that the individual falls within Paragraph 9's definition of “Nonprofessional Subscriber.”
                    </div>
                </div>
                <div className='mt-sm txt-align-justify'>
                    <b>10. PERMITTED RECEIPT - </b> Subscriber may not receive Market Data from
                    Vendor, and Vendor may not provide Market Data to Subscriber, on a “Nonprofessional
                    Subscriber” basis unless Vendor first properly determines that Subscriber qualifies as a
                    “Nonprofessional Subscriber” as defined in Paragraph 9 and Subscriber in fact qualifies as a
                    “Nonprofessional Subscriber.”
                </div>
                <div className='mt-sm txt-align-justify'>
                    <b>11. PERMITTED USE – </b> If Subscriber is a Nonprofessional Subscriber, he or she
                    shall receive Market Data solely for his or her personal, non-business use.
                </div>
                <div className='mt-sm txt-align-justify'>
                    <b>12. PERSONAL AND EMPLOYMENT DATA – </b> As a prerequisite to qualifying
                    as a "Nonprofessional Subscriber", Subscriber shall provide the following information:
                    <div className="mt-sm">
                        <div >
                            <div className="mt-sm">
                                <div>
                                    <label className="txt-weight-bold">Subscriber's Name and Address</label>
                                </div>
                                <div className="col-12">
                                    <div className="col-12 mt-sm">
                                        <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                        ${errors.subscriberFirstName ? 'b-xs-shaded-4' : ''}`} id="subscriberFirstName"
                                            name="subscriberFirstName" placeholder='First Name'
                                            value={inputValues.subscriberFirstName}
                                            onBlur={handleBlur}
                                            onChange={event => {
                                                onChange(event.target.name, event.target.value);
                                                handleInputChange(event.target.name, event.target.value);
                                            }} />
                                        {errors.subscriberFirstName && <span style={{ color: 'red' }}>{errors.subscriberFirstName}</span>}
                                    </div>
                                    <div className="col-12 mt-sm">
                                        <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                        ${errors.subscriberLastName ? 'b-xs-shaded-4' : ''}`} id="subscriberLastName"
                                            name="subscriberLastName" placeholder='Last Name'
                                            value={inputValues.subscriberLastName}
                                            onBlur={handleBlur}
                                            onChange={event => {
                                                onChange(event.target.name, event.target.value);
                                                handleInputChange(event.target.name, event.target.value);
                                            }} />
                                        {errors.subscriberLastName && <span style={{ color: 'red' }}>{errors.subscriberLastName}</span>}
                                    </div>
                                    <div className="col-12 mt-sm">
                                        <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                        ${errors.subscriberAddressOne ? 'b-xs-shaded-4' : ''}`} id="subscriberAddressLine1"
                                            name="subscriberAddressOne" placeholder='Address Line1'
                                            value={inputValues.subscriberAddressOne}
                                            onBlur={handleBlur}
                                            onChange={event => {
                                                onChange(event.target.name, event.target.value);
                                                handleInputChange(event.target.name, event.target.value);
                                            }} />
                                        {errors.subscriberAddressOne && <span style={{ color: 'red' }}>{errors.subscriberAddressOne}</span>}
                                    </div>
                                    <div className="col-12 mt-sm">
                                        <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                        ${errors.subscriberAddressTwo ? 'b-xs-shaded-4' : ''}`} id="subscriberAddressLine2"
                                            name="subscriberAddressTwo" placeholder='Address Line2'
                                            value={inputValues.subscriberAddressTwo}
                                            onBlur={handleBlur}
                                            onChange={event => {
                                                onChange(event.target.name, event.target.value);
                                                handleInputChange(event.target.name, event.target.value);
                                            }} />
                                        {errors.subscriberAddressTwo && <span style={{ color: 'red' }}>{errors.subscriberAddressTwo}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-sm">
                                <div>
                                    <label className="txt-weight-bold">Subscriber's occupations
                                        (list all
                                        occupations – including homemaker, student, retiree,
                                        etc.):</label>
                                </div>
                                <div className='txt-size-sm'>
                                    <select name="employerOccupation" id="occupation"
                                        className={`col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 mt-sm p-sm txt-size-xs
                                         ${errors.employerOccupation ? 'b-xs-shaded-4' : ''}`}
                                         onBlur={handleBlur}
                                         value={inputValues.employerOccupation} 
                                        onChange={event => {
                                            onChange(event.target.name, event.target.value);
                                            handleInputChange(event.target.name, event.target.value);
                                        }} >
                                        <option value="" disabled >
                                            Occupation List
                                        </option>
                                        <option value="selfEmployed">Self Employed</option>
                                        <option value="employed">Employed</option>
                                        <option value="retired">Retired</option>
                                        <option value="student">Student</option>
                                        <option value="homemaker">Homemaker</option>
                                    </select>
                                    {errors.employerOccupation && <span style={{ color: 'red' }}>{errors.employerOccupation}</span>}
                                </div>
                            </div>
                            <div className="mt-sm">
                                <div>
                                    <label className="txt-weight-bold">Name(s) and address(es) of
                                        Subscriber's employer(s):</label>
                                </div>

                                <div className="col-12 mt-sm">
                                    <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                    ${errors.employerName ? 'b-xs-shaded-4' : ''}`} id="employerName" 
                                    name="employerName" placeholder='Employer Name'
                                    value={inputValues.employerName}
                                    onBlur={handleBlur}
                                    onChange={event => {
                                        onChange(event.target.name, event.target.value);
                                        handleInputChange(event.target.name, event.target.value);
                                    }} />
                                {errors.employerName && <span style={{ color: 'red' }}>{errors.employerName}</span>}
                                </div>
                                <div className="col-12 mt-sm">
                                    <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                    ${errors.employerStreet ? 'b-xs-shaded-4' : ''}`} id="employerStreet"
                                     name="employerStreet" placeholder='Employer Street'
                                     value={inputValues.employerStreet}
                                     onBlur={handleBlur}
                                     onChange={event => {
                                         onChange(event.target.name, event.target.value);
                                         handleInputChange(event.target.name, event.target.value);
                                     }} />
                                 {errors.employerStreet && <span style={{ color: 'red' }}>{errors.employerStreet}</span>}
                                </div>
                                <div className="col-12 mt-sm">
                                    <input type="text" className={`p-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                    ${errors.employerCity? 'b-xs-shaded-4' : ''}`} id="employerCity"
                                     name="employerCity" placeholder='Employer City'
                                     value={inputValues.employerCity}
                                     onBlur={handleBlur}
                                     onChange={event => {
                                         onChange(event.target.name, event.target.value);
                                         handleInputChange(event.target.name, event.target.value);
                                     }} />
                                 {errors.employerCity && <span style={{ color: 'red' }}>{errors.employerCity}</span>}
                                </div>

                            </div>
                            <div className="mt-sm">
                                <div>
                                    <label className="txt-weight-bold">Subscriber's title(s) and/or
                                        position(s):</label>
                                </div>
                                <div>
                                    <input type="text" className={`p-sm mt-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2
                                     ${errors.subscriberTitle ? 'b-xs-shaded-4' : ''}`} id="titleorPosition"
                                      name="subscriberTitle" placeholder='Title(s) and/or position(s)'
                                      value={inputValues.subscriberTitle}
                                      onBlur={handleBlur}
                                      onChange={event => {
                                          onChange(event.target.name, event.target.value);
                                          handleInputChange(event.target.name, event.target.value);
                                      }} />
                                  {errors.subscriberTitle && <span style={{ color: 'red' }}>{errors.subscriberTitle}</span>}
                                </div>
                            </div>
                            <div className="mt-sm">
                                <div>
                                    <label className="txt-weight-bold">Subscriber's employment
                                        functions
                                        (description):</label>
                                </div>
                                <div>
                                    <input type="text" className={`p-sm mt-sm txt-size-xs col-12 bg-color-paper-1 b-xs-shaded-1 b-rad-xs txt-color-body-shaded-2 
                                        ${errors.subscriberEmploymentFunctions ? 'b-xs-shaded-4' : ''}`} id="subscriberEmploymentFunctions" 
                                        name="subscriberEmploymentFunctions" placeholder='Employment Functions' 
                                        value={inputValues.subscriberEmploymentFunctions}
                                        onBlur={handleBlur}
                                        onChange={event => {
                                            onChange(event.target.name, event.target.value);
                                            handleInputChange(event.target.name, event.target.value);
                                        }} />
                                    {errors.subscriberEmploymentFunctions && <span style={{ color: 'red' }}>{errors.subscriberEmploymentFunctions}</span>}
                                </div>
                            </div>
                            <div className='mt-sm'>In order to facilitate a decision as to whether Subscriber qualifies as a
                                "Nonprofessional
                                Subscriber," Subscriber shall answer the following questions: </div>
                                <div className='ml-md'>
                            <div className='mt-sm pl-sm'>
                                <div>A. Do you use Market Data solely for your personal, non-business use?</div>
                                <div className='display-flex mt-sm'>
                                    <input className="mlr-sm" type="radio" id="personalUseCheckbox" value="yes" checked={answers.set1 === 'yes'}
                                        name="personalUse" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set1', 'yes') }} />
                                    <label > Yes</label>
                                    <input className="mlr-sm" type="radio" id="businessUseCheckbox" value="no" checked={answers.set1 === 'no'}
                                        name="personalUse" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set1', 'no') }} />
                                    <label > No</label>
                                </div>
                            </div>
                            <div className='mt-sm pl-sm'>
                                <div>B. Do you receive Market Data for your business or any other entity?</div>
                                <div className='display-flex mt-sm'>
                                    <input className="mlr-sm" type="radio" id="personalUseCheckbox" value="yes" checked={answers.set2 === 'yes'}
                                        name="receiveMarketDataToBusiness" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set2', 'yes') }} />
                                    <label > Yes</label>
                                    <input className="mlr-sm" type="radio" id="businessUseCheckbox" value="no" checked={answers.set2 === 'no'}
                                        name="receiveMarketDataToBusiness" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set2', 'no') }} />
                                    <label > No</label>
                                </div>
                            </div>
                            <div className='mt-sm pl-sm'>
                                <div>C. Are you currently registered or qualified with the SEC or the CFTC?</div>
                                <div className='display-flex mt-sm'>
                                    <input className="mlr-sm" type="radio" id="personalUseCheckbox" value="yes" checked={answers.set3 === 'yes'}
                                        name="registeredWithSECorCFTC" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set3', 'yes') }} />
                                    <label  > Yes</label>
                                    <input className="mlr-sm" type="radio" id="businessUseCheckbox" value="no" checked={answers.set3 === 'no'}
                                        name="registeredWithSECorCFTC" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set3', 'no') }} />
                                    <label > No</label>
                                </div>
                            </div>
                            <div className='mt-sm pl-sm'>
                                <div>D. Are you currently registered or qualified with any securities agency, any
                                    securities
                                    exchange,
                                    association or regulatory body, or any commodities or futures contract market,
                                    association or
                                    regulatory body, in the United States or elsewhere?
                                </div>
                                <div className='display-flex mt-sm'>
                                    <input className="mlr-sm" type="radio" id="personalUseCheckbox" value="yes" checked={answers.set4 === 'yes'}
                                        name="registeredWithSecuritiesAgency" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set4', 'yes') }} />
                                    <label> Yes</label>
                                    <input className="mlr-sm" type="radio" id="businessUseCheckbox" value="no" checked={answers.set4 === 'no'}
                                        name="registeredWithSecuritiesAgency" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set4', 'no') }} />
                                    <label> No</label>
                                </div>
                            </div>
                            <div className='mt-sm pl-sm'>
                                <div>E. Whether you are located within or outside of the United States, do you perform
                                    any
                                    functions
                                    that are similar to those that require an individual to register or qualify with
                                    the
                                    SEC, the
                                    CFTC, any other securities agency or regulatory body, any securities exchange or
                                    association,
                                    or any commodities or futures contract market, association or regulatory body?
                                </div>
                                <div className='display-flex mt-sm'>
                                    <input className="mlr-sm" type="radio" id="personalUseCheckbox" value="yes" checked={answers.set5 === 'yes'}
                                        name="performFunctions" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set5', 'yes') }} />
                                    <label > Yes</label>
                                    <input className="mlr-sm" type="radio" id="businessUseCheckbox" value="no" checked={answers.set5 === 'no'}
                                        name="performFunctions" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set5', 'no') }} />
                                    <label> No</label>
                                </div>
                            </div>
                            <div className='mt-sm pl-sm'>
                                <div>F. Are you engaged to provide investment advice to any individual or entity?</div>
                                <div className='display-flex mt-sm'>
                                    <input className="mlr-sm" type="radio" id="personalUseCheckbox" value="yes" checked={answers.set6 === 'yes'}
                                        name="engagedToProvideInvestmentAdvice" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set6', 'yes') }} />
                                    <label > Yes</label>
                                    <input className="mlr-sm" type="radio" id="businessUseCheckbox" value="no" checked={answers.set6 === 'no'}
                                        name="engagedToProvideInvestmentAdvice" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set6', 'no') }} />
                                    <label> No</label>
                                </div>
                            </div>
                            <div className='mt-sm pl-sm'>
                                <div>G. Are you engaged as an asset manager?</div>
                                <div className='display-flex mt-sm'>
                                    <input className="mlr-sm" type="radio" id="personalUseCheckbox" value="yes" checked={answers.set7 === 'yes'}
                                        name="engagedAsAssetManager" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set7', 'yes') }} />
                                    <label> Yes</label>
                                    <input className="mlr-sm" type="radio" id="businessUseCheckbox" value="no" checked={answers.set7 === 'no'}
                                        name="engagedAsAssetManager" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set7', 'no') }} />
                                    <label> No</label>
                                </div>
                            </div>
                            <div className='mt-sm pl-sm'>
                                <div>H. Do you use the capital of any other individual or entity in the conduct of your
                                    trading?
                                </div>
                                <div className='display-flex mt-sm'>
                                    <input className="mlr-sm" type="radio" id="personalUseCheckbox" value="yes" checked={answers.set8 === 'yes'}
                                        name="useCapitalOfAnyOtherIndividualOrEntity" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set8', 'yes') }} />
                                    <label> Yes</label>
                                    <input className="mlr-sm" type="radio" id="businessUseCheckbox" value="no" checked={answers.set8 === 'no'}
                                        name="useCapitalOfAnyOtherIndividualOrEntity" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set8', 'no') }} />
                                    <label> No</label>
                                </div>
                            </div>
                            <div className='mt-sm pl-sm'>
                                <div>I. Do you conduct trading for the benefit of a corporation, partnership, or other
                                    entity?
                                </div>
                                <div className='display-flex mt-sm'>
                                    <input className="mlr-sm" type="radio" id="personalUseCheckbox" value="yes" checked={answers.set9 === 'yes'}
                                        name="conductTradingForBenefitOfCorporation" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set9', 'yes') }} />
                                    <label> Yes</label>
                                    <input className="mlr-sm" type="radio" id="businessUseCheckbox" value="no" checked={answers.set9 === 'no'}
                                        name="conductTradingForBenefitOfCorporation" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set9', 'no') }} />
                                    <label> No</label>
                                </div>
                            </div>
                            <div className='mt-sm pl-sm'>
                                <div>J. Have you entered into any agreement to share the profit of your trading
                                    activities or
                                    receive
                                    compensation for your trading activities?
                                </div>
                                <div className='display-flex mt-sm'>
                                    <input className="mlr-sm" type="radio" id="personalUseCheckbox" value="yes" checked={answers.set10 === 'yes'}
                                        name="shareProfitOrCompensationForTrading" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set10', 'yes') }} />
                                    <label> Yes</label>
                                    <input className="mlr-sm" type="radio" id="businessUseCheckbox" value="no" checked={answers.set10 === 'no'}
                                        name="shareProfitOrCompensationForTrading" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set10', 'no') }} />
                                    <label> No</label>
                                </div>
                            </div>
                            <div className='mt-sm pl-sm'>
                                <div>K. Are you receiving office space, and equipment or other benefits in exchange for
                                    your
                                    trading
                                    or work as a financial consultant to any person, firm or business entity?
                                </div>
                                <div className='display-flex mt-sm'>
                                    <input className="mlr-sm" type="radio" id="personalUseCheckbox" value="yes" checked={answers.set11 === 'yes'}
                                        name="receivingBenifitsForFinancialConsulting" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set11', 'yes') }} />
                                    <label> Yes</label>
                                    <input className="mlr-sm" type="radio" id="businessUseCheckbox" value="no" checked={answers.set11 === 'no'}
                                        name="receivingBenifitsForFinancialConsulting" onChange={event => { onChange(event.target.name, event.target.value); handleChange('set11', 'no') }} />
                                    <label> No</label>
                                </div>
                            </div>
                            </div>

                            <div>
                                <p className='txt-align-justify mt-sm'>Subscriber shall notify Vendor promptly in writing of any
                                    change in his or her circumstances
                                    that
                                    may cause him or her to cease to qualify as a Nonprofessional Subscriber or that may
                                    change his
                                    or her response to any of the preceding questions</p>
                            </div>
                            {!isValid() && allChecked &&
                                <div className="alert alert-warning mb-0 mt-1 mb-1">
                                    based on your answers, you are not eligible to change the feed level of this exchange. please contact our call center for assistance
                                </div>}
                        </div>
                    </div>
                </div>
                <div className='txt-weight-bold'> 13. CERTIFICATION</div>
                </div>
                <div className='bg-color-paper-3 p-sm mt-sm'>
                    - By executing this Agreement, Subscriber hereby certifies that he
                    or she falls within Paragraph 9's definition of "Nonprofessional Subscriber" and that the personal
                    and employment information that he or she has included in Paragraph 12 is truthful and accurate.
                    <div className='display-flex justify-content-end'>
                        <input type="checkbox" id="certification" name="certificationOk" value="iAgree" 
                        checked={checkedValues[1]}
                        onChange={event => {
                          onChange(event.target.name, event.target.value);
                          (handleCheckboxChange(1));
                      }}/>  
                    </div>
                </div>
                <div className='bg-color-paper-3 p-sm mt-sm'>
                    <b>ACCEPTED AND AGREED: </b>I, the "Subscriber" to which the preceding terms
                    and conditions refer, acknowledge that I have read the preceding terms and conditions of this
                    Section 2, that I understand them and that I hereby manifest my assent to, and my agreement to
                    comply with, those terms and conditions by "clicking" on the following box:
                    <div className='display-flex justify-content-end'>
                        <input type="checkbox" id="acceptedAndAgreed" name="acceptedAndAgreedSec2" value="iAgree" 
                        checked={checkedValues[2]}
                        onChange={event => {
                          onChange(event.target.name, event.target.value);
                          (handleCheckboxChange(2));
                      }}/> 
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NYSEAgreementNonPro;
