/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/
export enum AgreementID {
    GENERAL = 'GENERAL',
    NASDAQ_NP = 'NASDAQ_UTP_NP',
    NYSE_AMEX_NP = 'NYSE_AMEX_NP',
    NASDAQ_TV_NP = 'TOTAL_VIEW_NP',
    OPRA_NP = 'OPRA_NP',
    CBOE_NP = 'CBOE_NP',
    BLUE_OCEAN = 'USON_NP'
}