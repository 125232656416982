/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/

import { getAppConfig } from '../config-management';
import axios from 'axios';
const config = getAppConfig();


export const validateUserWithOMS = async ( token: string ,userId: string, region: string): Promise<boolean> => {
    
    const regionUrls: { [key: string]: string } = {
        MENA: `${config.omsAuth.userValidate.DIFC.baseUrl}?USR_ID=${userId}`,
        ASIA: `${config.omsAuth.userValidate.Asia.baseUrl}?USR_ID=${userId}`,
    };

    // Get query URL for the specified region
    const queryUrl = regionUrls[region];
    if (!queryUrl) {
        console.error('Invalid region specified for OMS validation');
        return false;
    }

    try {
        console.log("queryUrl", queryUrl);
        
        // Fetch request with token in headers
        const response = await fetch(queryUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'ACCTKN': token,
            },
        });

        if (!response.ok) {
            console.error('Error in OMS token validation: ', response.statusText);
            return false;
        }

        const data = await response.json();
        console.log("OMS response", data);

        return data.VLDT_STS ?? false;
    } catch (error) {
        console.error('An error occurred during OMS validation:', error);
        return false;
    }
};


export const fetchRequiredAgreements = async (subscriptionData: any) => {
    const apiUrl = config.apiGateway.rest.endPoints.exchangeSubscriptions.getAgreement;
    try {
        const fetchAgreementsResponse = await axios.post(apiUrl, subscriptionData);
        
        if (fetchAgreementsResponse && fetchAgreementsResponse.data.response) {
            const responseData = fetchAgreementsResponse.data.response;

            if (responseData.Status === 'SUCCESS') {
                return {
                    success: true,
                    data: responseData,
                };
            } else {
                console.error('API returned failure:', responseData.ErrorDesc);
                return {
                    success: false,
                    error: responseData.ErrorDesc || 'API returned an error without a description.',
                };
            }
        } else {
            console.error('Unexpected response structure:', fetchAgreementsResponse);
            return {
                success: false,
                error: 'Unexpected response structure from the server.',
            };
        }
    } catch (error) {
        console.error('Error in fetchRequiredAgreements:', error);
        const errorMessage = error || 'An unexpected error occurred while calling get agreements API';
        return {
            success: false,
            error: errorMessage,
        };
    }
};

export const saveUserAgreementdata = async (userSignedInfo :any) => {
    const apiUrl = config.apiGateway.rest.endPoints.exchangeSubscriptions.saveAgreeData;
    
    try {
        const response = await axios.post(apiUrl, userSignedInfo);
        console.log("saveUserAgreementdata response :", response);

        if (response.data?.response?.Status === 'SUCCESS') {
            return { success: true, response }; 
        } else {
            console.error("Server responded with a failure status:", response);
            return { success: false, message: response || "Unknown server error" };
        }
    } catch (error) {
        console.error("Error during saveUserAgreementdata API call:", error);
        return { success: false, message: error || "Network error" };
    }
};

export const cancelAgreementSubmission = async (subscriptionRequestID : any) => {
    const apiUrl = config.apiGateway.rest.endPoints.exchangeSubscriptions.cancelSubmission;
    const submissionCanceldata = { subscriptionRequestID };

    try {
        const response = await axios.post(apiUrl, submissionCanceldata);
        console.log("cancelAgreementSubmission response :", response);

        if (response.data?.response?.Status === 'SUCCESS') {
            return { success: true, response };
        } else {
            console.error("Cancellation failed :", response);
            return { success: false, message: response || "Unknown error during submission cancellation" };
        }
    } catch (error) {
        console.error("Error during cancelAgreementSubmission API call:", error);
        return { success: false, message: error || "Network error" };
    }
};

export const allAgreementSignedUserConfirm = async (userSubscriptionInfo : any) => {
    const apiUrl = config.apiGateway.rest.endPoints.exchangeSubscriptions.confirm;

    try {
        const response = await axios.post(apiUrl, userSubscriptionInfo);
        console.log("allAgreementSignedUserConfirm response :", response);

        if (response.data?.response?.Status === 'SUCCESS') {
            return { success: true, response }; 
        } else {
            console.error("Confirmation failed :", response);
            return { success: false, message: response || "Unknown error during confirmation" };
        }
    } catch (error) {
        console.error("Error during allAgreementSignedUserConfirm API call:", error);
        return { success: false, message: error || "Network error" };
    }
};

export const cancelConfirmation = async (userSubscriptionInfo : any) => {
    const apiUrl = config.apiGateway.rest.endPoints.exchangeSubscriptions.cancelConfirmation;

    try {
        const response = await axios.post(apiUrl, userSubscriptionInfo);
        console.log("cancelConfirmation response :", response);

        if (response.data?.response?.Status === 'SUCCESS') {
            return { success: true, response }; 
        } else {
            console.error("Cancel confirmation failed :", response);
            return { success: false, message: response || "Unknown error during cancellation" };
        }
    } catch (error) {
        console.error("Error during cancelConfirmation API call:", error);
        return { success: false, message: error || "Network error" };
    }
};
