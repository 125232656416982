/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/
import { getAppConfig } from "../config-management";
const config = getAppConfig();

export const validations = {
  validateNonEmpty: (value: string) => {
    if (value && typeof value === "string") {
      return !(value.trim() === "");
    } else if (value && typeof value === "number") {
      return true;
    } else {
      return false;
    }
  },
};

export const getCurrentDateTime = () => {
  const now = new Date();
  return now.toISOString();
}; 

export const sendMessageToParentApp = (data: any) => {
  const allowedOrigins = config.messageListner.parentOrigin.originUrls;

  allowedOrigins.forEach(origin => {
      window.parent.postMessage(data, origin);
  });
};