/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/

export const userTypeMap = new Map<number, string>([
  [0, 'R'],
  [1, 'P']
]);

export const feedLevelMap = new Map<number, string>([
  [0, 'Delayed'],
  [1, 'L1'],
  [2, 'L2'],
  [3, 'Unsubscribe'],
  [4, 'Subscribe']
]);

export const regionMap = new Map<string, string>([
  ['MENA', 'me'],
  ['ASIA', 'asia']
]);

