/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
*/

import * as jose from "jose";

export const publicKey =
    "-----BEGIN PUBLIC KEY-----\n" +
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAu2fmKM6wb0CRzQKcdh4b\n" +
    "P3yoT9TOX2ADy/a7eNMvTinwFCYur9rKJEBb2YlXyNGuUbGe4Zeyh64P6r+qGlbd\n" +
    "JANq5S0Wr60/PU8iKLZiocpRsP15ildbN1EB5khQGm5U6e/BfnRByh+4lOqBNUfk\n" +
    "4zURCh+xnZ84SqQC2GoIv+pCJeipkMInE4Y8Cb+kXKcdAm+B727GuSQ664whfwqA\n" +
    "5roRHmZrjVjtjQAtaP2T4+Ss3o0WT9vuMbFCSF9Mdheq3gmrTEI5wHc6/yxbT8G7\n" +
    "hY6GjS8vcYbTyKKsMzreJy8f9S5CtVcG890/K1fw7ZeJtv/Fm/FdoE/Cm91zkm8S\n" +
    "3QIDAQAB\n" +
    "-----END PUBLIC KEY-----";

// export const generatekeys = async () => {
//     const { publicKey, privateKey } = await jose.generateKeyPair('RSA-OAEP-256', {
//         extractable: true,
//     });

//     const pkcs8Pem = await jose.exportPKCS8(privateKey)

//     const spkiPem = await jose.exportSPKI(publicKey)
// }

export async function encryptWithPublicKey(data: any, publicKeySpki: string) {
    try {
        const algo = "RSA-OAEP-256";
        const encAlgo = "A256GCM";
        const publicKey = await jose.importSPKI(publicKeySpki, algo);

        const jsonString = JSON.stringify(data);
        const jwe = await new jose.CompactEncrypt(
            new TextEncoder().encode(jsonString),
        )
            .setProtectedHeader({ alg: algo, enc: encAlgo })
            .encrypt(publicKey)

        return jwe;
    } catch (error) {
        console.error('Encryption error:', error);
        throw error;
    }
}