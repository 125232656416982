import { useState } from "react";

export const useInputHandlers = (initialValues: any) => {
    const [inputValues, setInputValues] = useState(initialValues);
  
    const [errors, setErrors] = useState({
        subscriber_userID: '',       
        subscriberSignature: '', 
        subscriberName: '',
        subscriberAddress: '',       
        subscriberCity: '', 
        subscriberCountry: '',
        subscriberEmail: '',
        subscriberInternalReference: ''
      });

    const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
        return emailRegex.test(email);
      };
  
    // Handle input change for all fields
    const handleInputChange = (name: string, value: string) => {
      setInputValues({ ...inputValues, [name]: value });
  
      // Clear error if the user starts typing
      if (value.trim()) {
        setErrors({ ...errors, [name]: '' });
      }
    };
  
    // Handle error event for validation
    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
  
      // Check if field is empty and set appropriate error message
      if (!value.trim()) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Please enter valid data'
        }));
      } else if (name === 'subscriberEmail' && !isValidEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: 'Enter a valid email address'
        }));
      } else {
        // Clear error on valid input
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: ''
        }));
      }
    };
  
    return { inputValues, errors, handleInputChange, handleBlur };
  };
