/*
 * Copyright 2023 Mubasher Financial Services (DIFC) Limited. All rights reserved.
 *
 * Unauthorized access, copying, publishing, sharing, reuse of algorithms, concepts, design patterns
 * and code level demonstrations are strictly prohibited without any written approval of
 * Mubasher Financial Services (DIFC) Limited.
 */

import AgreementLayout from "./layouts/AgreementLayout";
import "./assets/scss/index.scss";
import "./assets/themes/theme1-color-variables.css";
import "./assets/themes/theme2-color-variables.css";

const ESMAgreementApp = () => (
  // width-60--vw height--460-px
  <div className="light">
    <div className="ChildApp bg-color-paper-1">
      <div className="row">
      </div>
        <div className="col-12">
          <AgreementLayout />
      </div>
    </div>
  </div>

);

export default ESMAgreementApp;
